export default [
  {
    key: 'username',
    label: 'field.username',
    placeholder: 'placeholder.username',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
  {
    key: 'password',
    label: 'field.password',
    placeholder: 'placeholder.password',
    rules: 'required',
    type: 'password',
    cols: 12,
  },
]
